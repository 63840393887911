import { getCodelists } from "@services/codelistService";
import {
  action,
  configure,
  makeObservable,
  observable,
  reaction,
  runInAction,
  toJS,
} from "mobx";
import UserJourneyComponent from "../../src/store/UserJourneyComponent";
import { preserveQueryStringParams } from "../components/AppLink";
import { Actions, Categories, Labels, Messages } from "../constants";
import controlNames from "../constants/controlNames";
import { getDeviceType } from "../helpers";
import { getFilteredModelList } from "../helpers/controlLogic/bikeVehicleLookupNQS";
import googleAnalyticsHelper from "../helpers/googleAnalyticsHelper";
import { isNullOrUndefined } from "../helpers/isNullOrUndefined";
import vehicleLookupHelper from "../helpers/vehicleLookupHelper";
import ClickStreamProvider from "../providers/ClickStreamProvider";
import ClientLogging from "../providers/loggingProvider";
import SsoRedirectHandler from "../providers/ssoRedirectHandler";
import abtesting from "../services/abtestingService";
import http from "../services/httpService";
import session from "../services/sessionService";
import UserData from "./../models/userData";

configure({ enforceActions: "observed" });

class AppStore {
  currentPage = 1;
  currentPageName = "";
  currentPageUrl = "";
  activeDriver = 0;
  jsonPath = "";
  structureLoaded = false;
  pages = [];
  pageComponents = [];
  formData = new Map();
  forcedFormData = {};
  visibilityControls = new Map();
  UIState = new Map();
  journeyResults = new Map();
  marketingOptions = new Map();
  UserJourneySettings = {};
  currentPageSettings = {};
  validationRules = [];
  theme = "";
  quoteReference = "";
  vehicleModelsList = [];
  codelists = {};
  previousVehicleInitialControlVisibility = false;
  messageBoxOccupationVisibility = false;
  licenceDefenceOccupationOptInVisibility = false;
  LicenceDefenceOccupationOptInValue = "";
  messageBoxConvictionVisibility = true;
  licenceDefenceConvictionOptInVisibility = true;
  licenceDefenceConvictionOptInValue = "";
  searchInputUserInput = "";
  userData = {};
  submitSender = "";
  submitSenderRedirect = "";
  timespan = "";
  FunctionalityConfiguration = {};
  RefererTrackerSkinConfiguration = {};
  unsubscribeData = new Map();
  modificationsData = [];
  licenceProv = false;
  activeElementName = "";
  detectedPrevPage = new Map();
  addonFilters = new Map();
  userInteractionState = new Map();
  isPostcodeChecked = false;
  additionalRiderIndex = -1; // used by executeSaveAdditionalRider() and <dataListItem> to determine whether we are adding or modifying an additional rider. (DW)
  cardsVisible = {}; // new for TBI rebrand - states which info cards are visible, mostly to determine the display of other controls (DW)

  hasChanged = {}; // used to determine whether an control has been changed yet - object containing string keys and boolean values. Used for live validation. (DW)
  liveValidation = false; // boolean used to mark if live validation is used - initially set from userJourneySettings in App.jsx
  invalidControls = {}; //Contains controls validation state. Only works for live validation onChange. True=invalid false=valid.
  controlsToAvoidValidation = {};
  areValidationRulesLoaded = false;
  shouldRevalidate = new Set();
  shouldClearValidation = new Set();
  policyCarToBeRevealed = false;

  // This is used only to disable continue button when the user selects an address from postcode lookup till
  // the info card is shown, then the continue button is enabled again. This is to prevent users with slow internet
  // to proceed to next page till postcode lookup finishes.
  disableContinue = false;

  /* ADDRESS LOOKUP NQS  */
  addressState = {
    addressIsManual: false,
    infoCardVisible: false,
    addressListVisible: false,
    addressNotListedError: false,
    addressEmptyError: false,
    addressListValues: [],
    shouldFocusManualInput: false,
  };

  //** Add Driver logic fields, should be moved to differnt Store at some point
  isAddDriver = false;
  claimToDelete = -1;
  convictionToDelete = -1;
  riderToDelete = -1;
  driverToDelete = -1;
  addingNewDriver = false;
  addingClaimToAddDriver = false;
  addingConvictionToAddDriver = false;
  serverQuoteData = new Map();
  adUnits = {};
  adStorage = {};
  entryQueryString = "";
  deviceType = "";
  previousVehicleSearchValue = "";
  // 👇 keeping this value in sessionStorage to have the same behaviour on refresh
  // (server resets RecallStatus when calling /saveform endpoint, so we can't depend on 'recallState' prop)
  isForcedRedirectAdditionalRider = !!sessionStorage.getItem("requoteJourney");
  /** Specials parts of UI to hide. See `helpers/timeActions.js` */
  hiddenSpecials = new Set();
  isQuoteRecalled = false;
  hasUserSelectedClaimsConvictionsValue = false;
  hasUserSelectedAdditionalClaimsConvictionsValue = false;

  constructor() {
    makeObservable(this, {
      validationRules: observable,
      resetValidationRules: action,
      pushValidationRule: action,
      areValidationRulesLoaded: observable,
      setAreValidationRulesLoaded: action,
      currentPage: observable,
      currentPageName: observable,
      structureLoaded: observable,
      timespan: observable,
      formData: observable,
      visibilityControls: observable,
      unsubscribeData: observable,
      modificationsData: observable,
      licenceProv: observable,
      detectedPrevPage: observable,
      UIState: observable,
      UserJourneySettings: observable,
      currentPageSettings: observable,
      vehicleModelsList: observable,
      journeyResults: observable,
      FunctionalityConfiguration: observable,
      addonFilters: observable,
      hasChanged: observable,
      setHasChanged: action,
      userInteractionState: observable,
      additionalRiderIndex: observable,
      loadAppState: action,
      updateFormData: action,
      clearFormData: action,
      isAddDriver: observable,
      isPostcodeChecked: observable,
      cardsVisible: observable,
      controlsToAvoidValidation: observable,
      getPages: action,
      getPageComponents: action,
      getFormData: action,
      getVisibilityControls: action,
      getTimespan: action,
      getUIState: action,
      getSubmitSender: action,
      getJourneyResultsData: action,
      getUnsubscribeData: action,
      setUnsubscribeData: action,
      disableContinue: observable,
      setDisableContinue: action,
      addressState: observable,
      setAddressState: action,
      resetAddressState: action,
      getModificationsData: action,
      setModificationsData: action,
      getLicenceProv: action,
      setLicenceProv: action,
      setJourneyResultsData: action,
      getQuoteReference: action,
      getUserJourneySettings: action,
      getFunctionalityConfiguration: action,
      getUserData: action,
      getUserJourneyStructure: action,
      getSkinAdUnits: action,
      getDescriptionByCode: action,
      getDataSourceByControlName: action,
      getRecallState: action,
      getCurrentPageName: action,
      setUserData: action,
      setVisibilityControls: action,
      changeVisibilityControls: action,
      setUIState: action,
      setFormData: action,
      setCurrentPage: action,
      setCurrentPageName: action,
      setQuoteReference: action,
      setVehicleModelsList: action,
      setPageFormData: action,
      setSubmitSender: action,
      getQuoteSummaryControls: action,
      getDetectedPrevPage: action,
      setDetectedPrevPage: action,
      setIsQuoteRecalled: action,
      getIsQuoteRecalled: action,
      setHasUserSelectedClaimsConvictionsValue: action,
      getHasUserSelectedClaimsConvictionsValue: action,
      setHasUserSelectedAdditionalClaimsConvictionsValue: action,
      getHasUserSelectedAdditionalClaimsConvictionsValue: action,
      getIsAddDriver: action,
      setIsAddDriver: action,
      getIsPostcodeChecked: action,
      setIsPostcodeChecked: action,
      entryQueryString: observable,
      setEntryQueryString: action,
      getEntryQueryString: action,
      enableAddonFilter: action,
      disableAddonFilter: action,
      setUserInteractionState: action,
      setQuoteRefUid: action,
      getQuoteRefUid: action,
      forceSetFormData: action,
      getCardsVisible: action,
      setCardsVisible: action,
      getCardVisible: action,
      getAdditionalRiderIndex: action,
      setAdditionalRiderIndex: action,
      getLiveValidation: action,
      setLiveValidation: action,
      liveValidation: observable,
      getIsForcedRedirectAdditionalRider: action,
      setIsForcedRedirectAdditionalRider: action,
      isForcedRedirectAdditionalRider: observable,
      shouldRevalidate: observable,
      addShouldRevalidate: action,
      removeShouldRevalidate: action,
      shouldClearValidation: observable,
      addShouldClearValidation: action,
      removeShouldClearValidation: action,
      hiddenSpecials: observable,
      policyCarToBeRevealed: observable,
      setPolicyCarToBeRevealed: action,
    });

    this.disposerReactionOptional = reaction(
      () => this.getControlByName("optional-extras-select"),
      (optionalExtrasSelect) => {
        if (optionalExtrasSelect !== "" && optionalExtrasSelect !== undefined) {
          const optionalExtrasSelectJsArray = toJS(optionalExtrasSelect);
          Object.keys(optionalExtrasSelectJsArray).map((optionalExtrasSelectKey) => {
            this.setAddonFilter(
              optionalExtrasSelectKey,
              optionalExtrasSelectJsArray[optionalExtrasSelectKey]
            );
          });
        }
      }
    );
  }

  loadAppState = async () => {
    try {
      var loadformresult = {};

      const queryStringParams = {
        skinName: import.meta.env.VITE_SKIN,
        version: import.meta.env.VITE_VERSION,
        queryString: this.entryQueryString,
        currentPath: window.location.pathname,
      };

      const endpointloadform = `/${import.meta.env.VITE_PAGE_API_ENDPOINT}/loadform`;
      ClickStreamProvider.setFirstInteractionCookie();
      ClickStreamProvider.handleClickStreamEvent(
        Categories.Environment,
        Actions.Environment,
        Labels.UserAgent,
        navigator.userAgent
      );
      //capture device type
      ClickStreamProvider.handleClickStreamEvent(
        Categories.Environment,
        Actions.Environment,
        Labels.DeviceType,
        getDeviceType()
      );
      await http
        .get(endpointloadform, {
          params: queryStringParams,
          withCredentials: true,
        })
        .then((response) => {
          loadformresult = response;
          ClickStreamProvider.handleClickStreamEvent(
            Categories.API,
            Actions.LoadFormCall,
            Labels.Status,
            response.status
          );
        })
        .catch((ex) => {
          ClientLogging.logError(ex, "load form");
          ClickStreamProvider.handleClickStreamEvent(
            Categories.API,
            Actions.LoadFormCall,
            Labels.Status,
            Messages.LoadFormError
          );
        });

      const ssoRedirectHandler = new SsoRedirectHandler();
      ssoRedirectHandler.processLoadFormResult(loadformresult);

      if (loadformresult.data.prepopStatus) googleAnalyticsHelper.pushPrepopStatus();

      var userData = new UserData(
        loadformresult.data.quoteRef,
        loadformresult.data.timespan,
        loadformresult.data.collectedQuoteData,
        loadformresult.data.inProgressQuoteData,
        loadformresult.data.tokenValue,
        loadformresult.data.validationResult,
        this.userData.userJourneyStructure,
        loadformresult.data.jsonPath,
        loadformresult.data.activeDriver,
        loadformresult.data.responses,
        loadformresult.data.recallState,
        import.meta.env.VITE_SKIN,
        import.meta.env.VITE_VERSION,
        loadformresult.data.entryQueryString,
        getDeviceType(),
        loadformresult.data.uid,
        loadformresult.data.visitorId
      );
      AppStore.serverQuoteData = loadformresult.data.collectedQuoteData;
      AppStore.adUnits = loadformresult.data.adUnits;
      AppStore.adStorage = loadformresult.data.adStorage;
      AppStore.entryQueryString = loadformresult.data.entryQueryString;
      this.entryQueryString = loadformresult.data.entryQueryString;
      this.userData = userData;

      if (userData.quoteRef !== null) {
        this.quoteReference = userData.quoteRef;
      }

      this.timespan = loadformresult.data.timespan;

      const codelistsToFetch = this.getUserJourneySettings()?.codelists;
      if (codelistsToFetch) {
        this.codelists = await getCodelists(codelistsToFetch.join());
      }

      this.structureLoaded = true;
      session.update(userData.tokenValue);
      abtesting.update(import.meta.env.VITE_ABTESTING_VERSION);
    } catch (ex) {
      this.userData = null;
      ClientLogging.logError(ex, "Load state: ");
    }
  };

  loadValidationRules = (UserJourney) => {
    UserJourney.Pages.map((p) => {
      p.Components &&
        p.Components.map((pc) => {
          pc.Controls &&
            pc.Controls.map((cc) => {
              if (cc.ValidationResources && cc.ValidationResources.length > 0) {
                cc.ValidationResources.map((v) => {
                  this.pushValidationRule({
                    field: cc.Name,
                    method: v.method,
                    validWhen: v.validWhen,
                    message: v.message,
                    args: v.args,
                    isInstant: v.isInstant,
                  });
                });
              }
              if (cc.Children.length > 0) {
                cc.Children.map((child) => {
                  if (child.ValidationResources && child.ValidationResources.length > 0) {
                    child.ValidationResources.map((v) => {
                      this.pushValidationRule({
                        field: child.Name,
                        method: v.method,
                        validWhen: v.validWhen,
                        message: v.message,
                        args: v.args,
                        isInstant: v.isInstant,
                      });
                    });
                  }
                });
              }
            });
        });
    });
    this.setAreValidationRulesLoaded(true);
  };

  setAreValidationRulesLoaded = (loaded) => {
    this.areValidationRulesLoaded = loaded;
  };

  getPages = () => {
    if (this.pages.length > 1) {
      var entryPageName = window.location.pathname
        .replace(import.meta.env.VITE_SITE_ROUTE, "")
        .replace(/\//g, "");
      const exists = this.pages.find(
        (p) =>
          p.url.toLowerCase() === entryPageName.toLowerCase() ||
          (!isNullOrUndefined(p.pageSettings.CompatibilityPaths) &&
            p.pageSettings.CompatibilityPaths.some(
              (path) => path.toLowerCase() === entryPageName.toLowerCase()
            ))
      );
      if (
        exists === undefined &&
        entryPageName.length >= 2 &&
        entryPageName != "unsubcribe" &&
        entryPageName != "pagenotfound" &&
        entryPageName != "nosession" &&
        entryPageName != "applicationerror"
      ) {
        window.location.replace(
          preserveQueryStringParams(`${import.meta.env.VITE_SITE_ROUTE}/pagenotfound`)
        );
      }
    }
    return toJS(this.pages);
  };

  getSendFullQuoteData = () => {
    return import.meta.env.VITE_CLICKSTREAM_SEND_FULL_JOURNEY;
  };

  getUnsubscribeData = () => {
    return this.unsubscribeData;
  };

  getModificationsData = () => {
    return toJS(this.modificationsData);
  };

  getLicenceProv = () => {
    return this.licenceProv;
  };

  getTimespan = () => {
    return this.timespan;
  };

  getPageComponents = () => {
    return toJS(this.pageComponents);
  };

  getComponentFromPage = async (pageUrl, componentIndex) => {
    const components = await UserJourneyComponent(pageUrl);
    return components[0].Controls[componentIndex];
  };

  getJsonPath = (pageIndex) => {
    if (pageIndex === undefined) {
      return this.jsonPath;
    }

    let currentPageUrl = this.pages.find((p) => p.orderNo === pageIndex).url;
    let returnedJsonPath = "";

    switch (currentPageUrl) {
      case "accidentinformation":
      case "convictioninformation":
      case "additionaldriverinformation":
        returnedJsonPath = this.jsonPath;
        break;
      default:
        returnedJsonPath = "";
        break;
    }

    return returnedJsonPath;
  };

  getJourneyResultsData = () => {
    return this.journeyResults;
  };

  getUIState = () => {
    return this.UIState;
  };

  getVisibilityControls = () => {
    return this.visibilityControls;
  };

  getFunctionalityConfiguration = () => {
    return toJS(this.FunctionalityConfiguration);
  };

  getFormData = (pageIndex) => {
    if (pageIndex === undefined) {
      return this.formData;
    }
    let data = {};
    const page = this.pages.find((p) => p.orderNo === pageIndex);

    let parsedFormData = Array.from(toJS(this.formData), ([key, value]) => ({
      key,
      value,
    }));

    this.pageComponents
      .filter((p) => p.url === page.url)
      .forEach((pc) => {
        let datain = {};

        pc.components &&
          pc.components[0].Controls.forEach((cc) => {
            var item = parsedFormData.find((p) => {
              if (p.key === cc.Name) {
                return p;
              }
            });

            cc.Children.forEach((child) => {
              let item = parsedFormData.find((p) => {
                if (p.key === child.Name) {
                  return p;
                }
              });

              child.Children.forEach((childc) => {
                let item = parsedFormData.find((p) => {
                  if (p.key === childc.Name) {
                    return p;
                  }
                });

                if (item !== undefined) {
                  datain[item.key] = item.value;
                }
              });

              if (item !== undefined) {
                datain[item.key] = item.value;
              }
            });

            if (item !== undefined) {
              data[item.key] = item.value;
            }
          });

        if (Object.entries(datain).length > 0) Object.assign(data, datain);
      });

    Object.keys(this.forcedFormData).forEach(
      (key) => (data[key] = this.forcedFormData[key])
    );
    return data;
  };

  getServerQuoteData = (pageIndex) => {
    if (pageIndex === undefined) {
      return this.serverQuoteData;
    }

    let data = {};
    const page = this.pages.find((p) => p.orderNo === pageIndex);

    let parsedFormData = Array.from(toJS(this.serverQuoteData), ([key, value]) => ({
      key,
      value,
    }));

    this.pageComponents
      .filter((p) => p.url === page.url)
      .forEach((pc) => {
        let datain = {};

        pc.components &&
          pc.components[0].Controls.forEach((cc) => {
            var item = parsedFormData.find((p) => {
              if (p.key === cc.Name) {
                return p;
              }
            });

            cc.Children.forEach((child) => {
              let item = parsedFormData.find((p) => {
                if (p.key === child.Name) {
                  return p;
                }
              });

              child.Children.forEach((childc) => {
                let item = parsedFormData.find((p) => {
                  if (p.key === childc.Name) {
                    return p;
                  }
                });

                if (item !== undefined) {
                  datain[item.key] = item.value;
                }
              });

              if (item !== undefined) {
                datain[item.key] = item.value;
              }
            });

            if (item !== undefined) {
              data[item.key] = item.value;
            }
          });

        if (Object.entries(datain).length > 0) Object.assign(data, datain);
      });

    return data;
  };

  getValidationRules = () => {
    return toJS(this.validationRules);
  };

  resetValidationRules = () => {
    this.validationRules = [];
  };

  pushValidationRule = (rule) => {
    this.validationRules.push(rule);
  };

  getControlByName = (controlName) => {
    return this.formData.get(controlName);
  };

  getJSControlValue = (controlName) => {
    return toJS(this.formData.get(controlName));
  };

  getQuoteReference = () => {
    return this.userData.quoteRef;
  };

  getUserJourneySettings = () => {
    return toJS(this.UserJourneySettings);
  };

  setUserJourneySettings = (ujs) => {
    this.UserJourneySettings = ujs;
  };

  getUserData = () => {
    return toJS(this.userData);
  };

  getUserJourneyStructure = () => {
    return toJS(this.userData.userJourneyStructure);
  };

  getSkinAdUnits = () => {
    return AppStore.adUnits;
  };

  getAdProperties = (currentPageName, currentSlotName, entryQueryString) => {
    try {
      var adUnits = this.getSkinAdUnits();
      var defaultAd = null;
      var returnedAd = null;

      adUnits?.forEach((item) => {
        if (
          item.pages != null &&
          item.pages.includes(currentPageName) &&
          item.slotName == currentSlotName &&
          item.slotDefaultAd === true
        ) {
          defaultAd = item;
          return;
        }
      });

      if (!isNullOrUndefined(entryQueryString)) {
        adUnits?.forEach((item) => {
          if (
            item.pages != null &&
            item.pages.includes(currentPageName) &&
            item.slotName == currentSlotName &&
            item.correspondingAd != null
          ) {
            if (
              item.correspondingAd.operator != null &&
              item.correspondingAd.operator.toLowerCase() == "and"
            ) {
              //// AND
              if (item.correspondingAd.queryStringParams != null) {
                returnedAd = item;
                item.correspondingAd.queryStringParams.forEach((itemQueryStringPrm) => {
                  if (
                    !entryQueryString
                      .toLowerCase()
                      .includes(itemQueryStringPrm.toLowerCase())
                  ) {
                    returnedAd = null;
                    return;
                  }
                });
              }
            } else {
              //// default OR
              if (item.correspondingAd.queryStringParams != null) {
                returnedAd = null;
                item.correspondingAd.queryStringParams.forEach((itemQueryStringPrm) => {
                  if (
                    entryQueryString
                      .toLowerCase()
                      .includes(itemQueryStringPrm.toLowerCase())
                  ) {
                    returnedAd = item;
                    return;
                  }
                });
              }
            }
          }
        });
      }
      if (returnedAd === null) returnedAd = defaultAd;
      return returnedAd;
    } catch (ex) {
      ClientLogging.logError(ex, "getAdProperties");
    }
  };

  getResponsiveAd = (currentAd) => {
    var storageDetails = AppStore.adStorage;
    let pictureElement = "<picture>";
    var browserSizes = [];
    var adSizes = currentAd.adSizes;
    var currentSlotSizes = [];
    adSizes.forEach((s) => {
      var brSize = s.browserSize.split(",", 1);
      browserSizes.push(parseInt(brSize));
    });
    browserSizes.sort((a, b) => b - a);

    var imageUrl = "";
    for (let i = 0; i < browserSizes.length; i++) {
      var currentSlotSize = 0;
      var fileName = "";
      adSizes.forEach((s) => {
        if (s.browserSize.includes(browserSizes[i])) {
          currentSlotSize = s.slotSize;
          currentSlotSizes = currentSlotSize.split(",");
          fileName = s.fileName;
        }
        if (browserSizes[i] == "0") {
          currentSlotSize = 0;
        }
      });

      if (currentSlotSize == 0) imageUrl = "";
      else {
        imageUrl = `${
          import.meta.env.VITE_ADS_STORAGE_DOMAIN
        }/${storageDetails.skin.toLowerCase()}/${
          import.meta.env.VITE_ADS_STORAGE_VERSION
        }/${fileName}`;
      }
      pictureElement =
        pictureElement +
        '<source media="(min-width: ' +
        browserSizes[i] +
        'px)" srcset="' +
        imageUrl +
        '" />';
    }
    pictureElement =
      pictureElement +
      '<img src="' +
      imageUrl +
      '" alt=" "' +
      'width="' +
      currentSlotSizes[0] +
      '"' +
      'height="' +
      currentSlotSizes[1] +
      '"' +
      "/>";
    pictureElement = pictureElement + "</picture>";
    return pictureElement;
  };

  getAdForRank(rank, brokerResponses) {
    try {
      var brokerAds = this.getSkinAdUnits();
      var rankPassed = false;
      var premiumPassed = false;
      var returnedAd = null;
      var brokerDidNotQuote = true;

      brokerAds?.forEach((item) => {
        if (
          item.slotName === "DISPLAY_RESULTS_SLOT_POSITION" &&
          item.brokerAd != null &&
          item.brokerAd.displayAdRank === rank
        ) {
          for (var i = 0; i < brokerResponses.length; i++) {
            var br = brokerResponses[i];
            if (br.brokerName.toLowerCase() === item.brokerAd.brokerName.toLowerCase()) {
              brokerDidNotQuote = false;

              if (!isNullOrUndefined(item.brokerAd.brokerRankInterval)) {
                var rankInterval = item.brokerAd.brokerRankInterval.split(",");
                var lowerRankLimit = parseInt(rankInterval[0]);
                var upperRankLimit = parseInt(rankInterval[1]);
                if (i + 1 >= lowerRankLimit && i + 1 <= upperRankLimit) {
                  rankPassed = true;
                }
              }

              if (!isNullOrUndefined(item.brokerAd.brokerPremiumInterval)) {
                var premiumInterval = item.brokerAd.brokerPremiumInterval.split(",");
                var lowerPremiumLimit = parseFloat(premiumInterval[0]);
                var upperPremiumLimit = parseFloat(premiumInterval[1]);
                if (br.premium >= lowerPremiumLimit && br.premium <= upperPremiumLimit) {
                  premiumPassed = true;
                }
              }
              if (rankPassed || premiumPassed) {
                returnedAd = item;
              }
            }
          }
          if (item.brokerAd.brokerNotQuoted === true && brokerDidNotQuote === true) {
            returnedAd = item;
          }
        }
      });
      return returnedAd;
    } catch (ex) {
      ClientLogging.logError(ex, "getAdForRank");
      return null;
    }
  }

  getControlByLogicalName = (controlLogicalName) => {
    let userJourneyStructure = this.getUserJourneyStructure();
    let pages = [];
    let allControls = [];
    let returnedValue = "";

    pages = userJourneyStructure.Pages;
    pages.map((page) => {
      page.Components &&
        page.Components[0].Controls.forEach((control) => {
          allControls.push(control);
          control.Children.forEach((child) => {
            allControls.push(child);
          });
        });
    });
    allControls.forEach((control) => {
      if (control.LogicalName === controlLogicalName) {
        returnedValue = this.getControlByName(control.Name);
        return;
      }
    });
    return returnedValue;
  };

  getDescriptionByCode = (code, controlName) => {
    let userJourneyStructure = this.getUserJourneyStructure();
    let pages = [];
    let description = "";
    let controls = [];
    let controlMatch = {};
    let matchList = [];

    if (isNullOrUndefined(this.codelists[controlName])) {
      pages = userJourneyStructure.Pages;
      pages.map((page) => {
        page.Components &&
          page.Components[0].Controls.forEach((control) => {
            controls.push(control);
            control.Children.forEach((child) => {
              controls.push(child);
            });
          });
      });
      controls.forEach((c) => {
        if (c.Name === controlName) {
          matchList = c.ControlValuesList;
          controlMatch = c;
          return matchList;
        }
      });
    } else {
      matchList = this.codelists[controlName];
    }

    if (matchList && matchList.length > 0) {
      for (let element of matchList) {
        if (element.Key === code) {
          description = element.Value;
          break;
        }
      }
    }

    if (controlName === "offence-code") {
      // remove references to offence codes from offence desciption, if present
      if (description.includes(code)) {
        const newOffenceDescription = description.split("-")[1]?.trim();
        if (!isNullOrUndefined(newOffenceDescription) && newOffenceDescription !== "")
          description = newOffenceDescription;
      }
    }

    if (
      description === "" &&
      controlMatch?.Attributes?.codeListException &&
      controlMatch.Attributes.codeListException.code === code
    ) {
      description = controlMatch.Attributes.codeListException.value;
    }

    return description;
  };

  getDataSourceByControlName = (controlName) => {
    let userJourneyStructure = this.getUserJourneyStructure();
    let pages = [];
    let controls = [];
    let matchList = [];
    pages = userJourneyStructure.Pages;
    pages.map((page) => {
      page.Components &&
        page.Components[0].Controls.forEach((control) => {
          controls.push(control);

          control.Children.forEach((child) => {
            controls.push(child);
          });

          controls.forEach((c) => {
            if (c.Name === controlName) matchList = c.DataSource;
            return matchList;
          });
        });
    });
    return matchList;
  };

  getQuoteSummaryControls = async () => {
    let userJourneyStructure = this.getUserJourneyStructure();
    let pages = [];
    let controls = [];

    pages = userJourneyStructure.Pages;

    for (var i = 0; i < userJourneyStructure.Pages.length; i++) {
      var pg = userJourneyStructure.Pages[i];
      var comps = await UserJourneyComponent(pg.Url);
      pg.Components = comps;
    }

    pages.map((page) => {
      page.Components &&
        page.Components[0].Controls.forEach((control) => {
          if (
            control.IsSavedInQuoteSummary === true &&
            !isNullOrUndefined(control.Attributes)
          ) {
            controls.push({
              name: control.Name,
              label: control.Label,
              value: this.getControlByName(control.Name),
              valueType: control.Attributes.quoteSummaryValueType,
              section: control.Attributes.quoteSummarySection,
              subsection: control.Attributes.quoteSummarySubSection,
              customLabel: control.Attributes.quoteSummaryLabel,
            });
          }

          control.Children.forEach((child) => {
            if (
              child.IsSavedInQuoteSummary === true &&
              !isNullOrUndefined(child.Attributes)
            ) {
              controls.push({
                name: child.Name,
                label: child.Label,
                value: this.getControlByName(child.Name),
                valueType: child.Attributes.quoteSummaryValueType,
                section: child.Attributes.quoteSummarySection,
                subsection: child.Attributes.quoteSummarySubSection,
                customLabel: child.Attributes.quoteSummaryLabel,
              });
            }
          });
        });
    });
    return controls;
  };

  getControlsInOrder(pageToReturnControlsFor) {
    let userJourneyStructure = this.getUserJourneyStructure();
    let pages = [];
    let allControls = [];

    pages = userJourneyStructure.Pages;

    pages.map((page) => {
      if (page.Url.toUpperCase() === pageToReturnControlsFor.toUpperCase()) {
        page.Components &&
          page.Components[0].Controls.forEach((control) => {
            if (!isNullOrUndefined(control.Attributes)) {
              allControls.push(control);
            }

            control.Children.forEach((child) => {
              if (!isNullOrUndefined(child.Attributes)) {
                allControls.push(child);
              }
            });
          });
      }
    });
    return allControls;
  }

  getControlDetailsByName(subcontrol) {
    let returnedControl = [];
    let userJourneyStructure = this.getUserJourneyStructure();
    let pages = [];
    let allControls = [];

    pages = userJourneyStructure.Pages;
    pages.map((page) => {
      page.Components &&
        page.Components[0].Controls.forEach((control) => {
          if (
            control.IsSavedInQuoteSummary === true &&
            !isNullOrUndefined(control.Attributes)
          ) {
            allControls.push(control);
          }
          control.Children.forEach((child) => {
            if (
              child.IsSavedInQuoteSummary === true &&
              !isNullOrUndefined(child.Attributes)
            ) {
              allControls.push(child);
            }
          });
        });
    });
    allControls.forEach((control) => {
      if (control.Name === subcontrol[0]) {
        returnedControl.push({
          name: control.Name,
          label: control.Label,
          value: subcontrol[1],
          valueType: control.Attributes.quoteSummaryValueType,
          section: control.Attributes.quoteSummarySection,
          subsection: control.Attributes.quoteSummarySubSection,
          customLabel: control.Attributes.quoteSummaryLabel,
        });
        return;
      }
    });
    return returnedControl;
  }

  //This will only work on the current page
  getControlExists = (controlName) => {
    const userJourneyStructure = this.getUserJourneyStructure();

    return userJourneyStructure.Pages.some(
      (page) =>
        page.Components && page.Components[0].Controls.some((c) => c.Name === controlName)
    );
  };

  getCurrentDriver = () => {
    return this.activeDriver;
  };

  getPreviousVehicleInitialControVisibility = () => {
    return this.previousVehicleInitialControlVisibility;
  };

  getMessageBoxOccupationVisibility = () => {
    return this.messageBoxOccupationVisibility;
  };

  getMessageBoxConvictionVisibility = () => {
    return this.messageBoxConvictionVisibility;
  };

  getLicenceDefenceOccupationOptInVisibility = () => {
    return this.licenceDefenceOccupationOptInVisibility;
  };

  getLicenceDefenceConvictionOptInVisibility = () => {
    return this.licenceDefenceConvictionOptInVisibility;
  };

  getLicenceDefenceOccupationOptInValue = () => {
    return this.LicenceDefenceOccupationOptInValue;
  };

  getLicenceDefenceConvictionOptInValue = () => {
    return this.licenceDefenceConvictionOptInValue;
  };

  getSearchInputUserInput = () => {
    return this.searchInputUserInput;
  };

  getVehicleModelsList = () => {
    return toJS(this.vehicleModelsList);
  };

  getPageNameByIndex = (pageIndex) => {
    return this.pages.find((p) => p.orderNo === pageIndex).url;
  };

  getSubmitSender = () => {
    return this.submitSender;
  };

  getSubmitSenderRedirect = () => {
    return this.submitSenderRedirect;
  };

  getRecallState = () => {
    return this.userData.recallState;
  };

  getActiveElementName = () => {
    return this.activeElementName;
  };

  getEntryQueryString = () => {
    return this.entryQueryString;
  };

  getDetectedPrevPage = () => {
    let parsedDetectedPrevPage = Array.from(
      toJS(this.detectedPrevPage),
      ([key, value]) => ({
        key,
        value,
      })
    );
    return parsedDetectedPrevPage.length > 0
      ? parsedDetectedPrevPage[parsedDetectedPrevPage.length - 1].value
      : "";
  };

  getIsPostcodeChecked = () => {
    return this.isPostcodeChecked;
  };

  getPreviousVehicleSearchValue = () => {
    return this.previousVehicleSearchValue;
  };

  getVehiclePurchasedValue = () => {
    if (!isNullOrUndefined(this.getControlByName("vehicle-not-purchased")))
      return !this.getControlByName("vehicle-not-purchased");

    if (
      !isNullOrUndefined(this.getControlByName("vehicle-purchased")) &&
      this.getControlByName("vehicle-purchased").toLowerCase() === "yes"
    )
      return true;
    else return false;
  };

  getCurrentPageName = () => {
    return this.currentPageName;
  };

  // setters

  setEntryQueryString = (queryString) => {
    this.entryQueryString = queryString;
  };

  setActiveElementName = (submitButtonName) => {
    this.activeElementName = submitButtonName;
  };

  setJsonPath = (controlName) => {
    this.jsonPath = controlName;
  };

  setCurrentPage = (orderNo) => {
    this.currentPage = orderNo;
  };

  setCurrentPageName = (title) => {
    this.currentPageName = title;
  };

  setUnsubscribeData = (item) => {
    var inObj = {
      key: Object.keys(item)[0],
      value: item[Object.keys(item)[0]],
    };

    this.unsubscribeData.set(inObj.key, inObj.value);
  };

  setModificationsData = (item) => {
    this.modificationsData = item;
  };

  setLicenceProv = (value) => {
    this.licenceProv = value;
  };

  setDetectedPrevPage = (prevPage) => {
    var inObj = {
      key: Object.keys(prevPage)[0],
      value: prevPage[Object.keys(prevPage)[0]],
    };
    this.detectedPrevPage.set(inObj.key, inObj.value);
  };

  setPreviousVehicleSearchValue = (value) => {
    this.previousVehicleSearchValue = value;
  };

  /**
   * Method to set form data
   **/
  setFormData = (item) => {
    var inObj = {
      key: Object.keys(item)[0],
      value: item[Object.keys(item)[0]],
    };

    let userJourneyStructure = this.getUserJourneyStructure();
    let controls = [];
    let pages = userJourneyStructure.Pages;
    pages.map((page) => {
      page.Components &&
        page.Components[0].Controls.forEach((control) => {
          controls.push(control);

          control.Children?.forEach((child) => {
            controls.push(child);
          });
        });
    });
    controls.forEach((c) => {
      if (c.Name === inObj.key && c.IsSaveable !== false) {
        this.formData.set(inObj.key, inObj.value);
      } else return false;
    });
    return true;
  };

  //Used to force set control values regardless if they're missing from controls structure
  forceSetFormData = (name, value) => {
    this.forcedFormData[name] = value;
    this.formData.set(name, value);
  };

  removeFromForcedFormData = (name) => {
    delete this.forcedFormData[name];
  };

  setServerQuoteData = (item) => {
    var inObj = {
      key: Object.keys(item)[0],
      value: item[Object.keys(item)[0]],
    };

    let userJourneyStructure = this.getUserJourneyStructure();
    let controls = [];
    let pages = userJourneyStructure.Pages;
    pages.map((page) => {
      page.Components &&
        page.Components[0].Controls.forEach((control) => {
          controls.push(control);

          control.Children.forEach((child) => {
            controls.push(child);
          });

          controls.forEach((c) => {
            if (c.Name === inObj.key && c.IsSaveable !== false)
              this.serverQuoteData.set(inObj.key, inObj.value);
          });
        });
    });
  };

  setJourneyResultsData = (item) => {
    var inObj = {
      key: Object.keys(item)[0],
      value: item[Object.keys(item)[0]],
    };

    this.journeyResults.set(inObj.key, inObj.value);
  };

  clearJourneyResults = () => {
    runInAction(() => this.journeyResults.clear());
  };

  /**
   * Method to set visibility of a control by updating visibilityControls list with visibility true/false for each control
   **/
  setVisibilityControls = (item) => {
    var inObj = {
      key: Object.keys(item)[0],
      value: item[Object.keys(item)[0]],
    };
    let userJourneyStructure = this.getUserJourneyStructure();
    let controls = [];
    let pages = userJourneyStructure.Pages;
    pages.map((page) => {
      page.Components &&
        page.Components[0].Controls.forEach((control) => {
          controls.push(control);

          if (control.Children) {
            control.Children.forEach((child) => {
              controls.push(child);
            });
          }

          controls.forEach((c) => {
            if (c.Name === inObj.key) this.visibilityControls.set(inObj.key, inObj.value);
          });
        });
    });
  };

  changeVisibilityControls = (items, visibility) => {
    items.forEach((item) => {
      this.visibilityControls.set(item, visibility);
    });
  };

  /**
   * Method to manipulate local state for some controls by updating UIState list with true/false for each control needed. For example populate uk resindent from dob
   **/
  setUIState = (item) => {
    this.UIState.set(item.key, item.value);
  };

  /**
   * Method to keep vehicleModelList to be observable
   **/
  setVehicleModelsList = (valuesList) => {
    this.vehicleModelsList = valuesList;
  };

  setPreviousVehicleInitialControVisibility = (value) => {
    this.previousVehicleInitialControlVisibility = value;
  };

  setMessageBoxOccupationVisibility = (value) => {
    this.messageBoxOccupationVisibility = value;
  };

  setMessageBoxConvictionVisibility = (value) => {
    this.messageBoxConvictionVisibility = value;
  };

  setLicenceDefenceOccupationOptInVisibility = (value) => {
    this.licenceDefenceOccupationOptInVisibility = value;
  };

  setLicenceDefenceConvictionOptInVisibility = (value) => {
    this.licenceDefenceConvictionOptInVisibility = value;
  };

  setLicenceDefenceOccupationOptInValue = (value) => {
    this.LicenceDefenceOccupationOptInValue = value;
  };

  setLicenceDefenceConvictionOptInValue = (value) => {
    this.licenceDefenceConvictionOptInValue = value;
  };

  setSearchInputUserInput = (value) => {
    this.searchInputUserInput = value;
  };

  setQuoteReference = (quoteReference) => {
    this.userData.quoteRef = quoteReference;
  };

  setQuoteRefUid = (uid) => {
    this.userData.uid = uid;
  };

  getQuoteRefUid = (uid) => {
    return this.userData.uid;
  };

  setRecallState = (recallState) => {
    this.userData.recallState = recallState;
  };

  setUserData = (userData) => {
    this.userData = userData;
  };

  /**
   * Method to delete a control from formData obj
   **/
  deleteFormData = (controlName) => {
    runInAction(() => this.formData.delete(controlName));
  };

  /**
   * Method to delete an array of controls from formData obj
   */
  deleteFormDataControls = (controlsNames) => {
    try {
      controlsNames.forEach((controlName) => {
        runInAction(() => this.formData.delete(controlName));
      });
    } catch (err) {
      ClientLogging.logInfo(err, "Couldn't delete controls form data");
    }
  };

  /**
   * Method to make sure that accident/conviction/additional driver pages are cleared on load
   **/
  clearPageFormData = (pageName) => {
    runInAction(() => {
      if (pageName !== undefined) {
        this.pageComponents
          .filter((p) => p.url === pageName)
          .forEach((pc) => {
            pc.components &&
              pc.components[0] &&
              pc.components[0].Controls.forEach((cc) => {
                this.formData.delete(cc.Name);
                if (!isNullOrUndefined(cc.Children) && cc.Children.length > 0) {
                  cc.Children.forEach((child) => this.formData.delete(child.Name));
                }
              });
          });
      }
      // duplicated code from above, but for wiping live validation data for each control.
      if (this.liveValidation) {
        // if live validation is enabled in the user journey settings...
        if (pageName !== undefined) {
          this.pageComponents
            .filter((p) => p.url === pageName)
            .forEach((pc) => {
              pc.components &&
                pc.components[0] &&
                pc.components[0].Controls.forEach((cc) => {
                  this.setHasChanged(cc.Name, false); // define control as unchanged
                  if (!isNullOrUndefined(cc.Children) && cc.Children.length > 0)
                    cc.Children.forEach((child) => this.setHasChanged(child.Name, false)); // define child control as unchanged
                });
            });
        }
      }
    });
  };
  /**
   * Removes page data from collectedQuoteData
   **/
  removePageData = (pageName, collectedQuoteData) => {
    if (pageName !== undefined) {
      this.pageComponents
        .filter((p) => p.url === pageName)
        .forEach((pc) => {
          pc.components.length > 0 &&
            pc.components[0].Controls.forEach((cc) => {
              delete collectedQuoteData[cc.Name];
              if (!isNullOrUndefined(cc.Children) && cc.Children.length > 0)
                cc.Children.forEach((child) => delete collectedQuoteData[child.Name]);
            });
        });
    }
  };

  /**
   * Method to make sure that all controls on a specific page are null
   **/
  setNullPageFormData = (pageName) => {
    if (pageName !== undefined) {
      let controls = [];
      this.pageComponents
        .filter((p) => p.url === pageName)
        .forEach((pc) => {
          pc.components &&
            pc.components[0].Controls.forEach((cc) => {
              controls.push(cc);
              cc.Children.forEach((child) => {
                controls.push(child);
              });
            });
        });
      controls.forEach((t) => {
        this.setFormData({ [t.Name]: null });
      });
    }
  };
  /**
   * Method to make sure that  page is repopulated on modify
   **/
  setPageFormData = (pageName, jsonPath, index) => {
    if (pageName !== undefined) {
      let pageDetails = toJS(this.formData.get(jsonPath)[index]);
      for (let [key, value] of Object.entries(pageDetails)) {
        this.formData.set(key, value);
      }
    }
  };

  /**
   * Method to update form data with a list of controls
   **/
  updateFormData = (items) => {
    for (let [key, value] of Object.entries(items)) {
      this.formData.set(key, value);
    }
  };

  clearFormData = () => {
    this.formData.clear();
  };

  updateServerQuoteData = (items) => {
    for (let [key, value] of Object.entries(items)) {
      this.serverQuoteData.set(key, value);
    }
  };

  setIsQuoteRecalled = (value) => {
    this.isQuoteRecalled = value;
  };

  setHasUserSelectedClaimsConvictionsValue = (value) => {
    this.hasUserSelectedClaimsConvictionsValue = value;
  };

  setHasUserSelectedAdditionalClaimsConvictionsValue = (value) => {
    this.hasUserSelectedAdditionalClaimsConvictionsValue = value;
  };

  getIsQuoteRecalled = () => {
    return this.isQuoteRecalled;
  };

  getHasUserSelectedClaimsConvictionsValue = () => {
    return this.hasUserSelectedClaimsConvictionsValue;
  };

  getHasUserSelectedAdditionalClaimsConvictionsValue = () => {
    return this.hasUserSelectedAdditionalClaimsConvictionsValue;
  };

  setIsAddDriver = (value) => {
    this.isAddDriver = value;
  };

  getIsAddDriver = () => {
    return this.isAddDriver;
  };

  setClaimToDelete = (index) => {
    this.claimToDelete = index;
  };

  getClaimToDelete = () => {
    return this.claimToDelete;
  };

  setConvictionToDelete = (index) => {
    this.convictionToDelete = index;
  };

  getConvictionToDelete = () => {
    return this.convictionToDelete;
  };

  setAdditionalDriverToDelete = (index) => {
    this.driverToDelete = index;
  };

  getAdditionalDriverToDelete = () => {
    return this.driverToDelete;
  };

  /**
   * Method to set the active driver (main or aditional)
   **/
  setCurrentDriver = (driver) => {
    this.activeDriver = driver;
  };

  /**
   * Method to set the name of the submit button pressed. In case of claims/convictions/additional-drivers, we need to differentiate if
   * the submit button was continue button or add claims/convictions/additional-drivers
   **/
  setSubmitSender = (sender) => {
    this.submitSender = sender;
  };

  setSubmitSenderRedirect = (senderRedirect) => {
    this.submitSenderRedirect = senderRedirect;
  };

  enableAddonFilter = (filterName) => {
    this.addonFilters.set(filterName, true);
  };
  disableAddonFilter = (filterName) => {
    this.addonFilters.set(filterName, false);
  };
  clearAddonFilters = () => {
    this.addonFilters.clear();
  };

  setAddonFilter = (filterName, filterValue) => {
    this.addonFilters.set(filterName, filterValue);
  };

  getAddonFilterKey = (addonName) => {
    return addonName.toLowerCase().replace(/\s/g, "");
  };

  getAddonFilters = () => {
    return this.addonFilters;
  };

  getSelectedOptionalExtras = () => {
    return this.formData.get("optional-extras-select");
  };

  getSelectedOptionalExtrasWithAddonFilters = () => {
    const selectedExtras = this.getSelectedOptionalExtras();
    const valueList = [...this.addonFilters];

    if (selectedExtras) {
      valueList.push(...new Map(Object.entries(selectedExtras)));
    }

    return new Map(valueList);
  };

  setUserInteractionState = (items) => {
    for (let [key, value] of Object.entries(items)) {
      this.userInteractionState.set(key, value);
    }
  };

  setIsPostcodeChecked = (value) => {
    this.isPostcodeChecked = value;
  };

  getCardsVisible = () => {
    return this.cardsVisible;
  };

  setCardsVisible = (cardName, visibility) => {
    this.cardsVisible[cardName] = visibility;
  };

  getCardVisible = (cardName) => {
    return this.cardsVisible[cardName] === true;
  };

  // (DW)
  getAdditionalRiderIndex = () => {
    return this.additionalRiderIndex;
  };

  setAdditionalRiderIndex = (index) => {
    this.additionalRiderIndex = index;
  };

  getLiveValidation = () => {
    return this.liveValidation;
  };

  setLiveValidation = (bool) => {
    this.liveValidation = bool;
  };

  getControlsToAvoidValidation = () => {
    return this.controlsToAvoidValidation;
  };

  addControlToAvoidValidation = (controlName) => {
    this.controlsToAvoidValidation[`validator-${controlName}`] = true;
  };

  removeControlToAvoidValidation = (controlName) => {
    delete this.controlsToAvoidValidation[`validator-${controlName}`];
  };

  getIsForcedRedirectAdditionalRider = () => {
    return this.isForcedRedirectAdditionalRider;
  };

  setIsForcedRedirectAdditionalRider = (bool) => {
    this.isForcedRedirectAdditionalRider = bool;
  };

  getHasChanged = (controlName) => {
    return this.hasChanged[controlName];
  };

  setHasChanged = (controlName, hasChanged) => {
    this.hasChanged[controlName] = hasChanged;
  };

  setDisableContinue = (newValue) => {
    this.disableContinue = newValue;
  };

  getAddressState = () => {
    return this.addressState;
  };

  setAddressState = (newState) => {
    this.addressState = { ...this.addressState, ...newState };
  };

  resetAddressState = () => {
    this.addressState = {
      addressIsManual: false,
      infoCardVisible: false,
      addressListVisible: false,
      addressNotListedError: false,
      addressEmptyError: false,
      addressListValues: [],
    };
  };

  setFormRowAdsForCurrentPage = (currentPageName, components) => {
    try {
      var adUnits = this.getSkinAdUnits();
      var formRowAds = adUnits.filter(
        (ad) =>
          ad.slotName === "FORM_ROW_SLOT" && ad.formRowAd.pageName === currentPageName
      );
      let componentIndex = components.findIndex(
        (c) => c.Name.toLowerCase() === currentPageName
      );
      if (componentIndex === -1) {
        return;
      }
      let currentControls = components[componentIndex].Controls;

      if (formRowAds.length === 0) {
        return;
      } else {
        for (const ad of formRowAds) {
          if (
            currentControls.filter((c) => c.Name === ad.formRowAd.control.name).length > 0
          ) {
            continue;
          }

          let newControl = {};
          newControl.Children =
            ad.formRowAd.control.children == null ? [] : ad.formRowAd.control.children;
          newControl.Attributes = ad.formRowAd.control.attributes;
          newControl.CurrentValue = ad.formRowAd.control.currentValue;
          newControl.DefaultValue = ad.formRowAd.control.defaultValue;
          newControl.HelpMessages = ad.formRowAd.control.helpMessages;
          newControl.InfoMessages = ad.formRowAd.control.infoMessages;
          newControl.ExtraInfo = ad.formRowAd.control.extraInfo;
          newControl.IsSaveable = ad.formRowAd.control.isSaveable;
          newControl.Label = ad.formRowAd.control.label;
          newControl.LogicalName = ad.formRowAd.control.logicalName;
          newControl.Name = ad.formRowAd.control.name;
          newControl.ParentName = ad.formRowAd.control.parentName;
          newControl.Type = ad.formRowAd.control.type;
          newControl.ValidationResources = ad.formRowAd.control.validationResources;
          newControl.ControlValuesList = ad.formRowAd.control.values;

          currentControls.splice(ad.formRowAd.index, 0, newControl);
        }
        components[componentIndex].Controls = currentControls;

        let compIndex = this.pageComponents.findIndex((v) => v.url === currentPageName);

        this.pageComponents[compIndex].components = components;

        let pageIndex = this.userData.userJourneyStructure.Pages.findIndex(
          (v) => v.Url === currentPageName
        );

        this.userData.userJourneyStructure.Pages[pageIndex].Components = components;
      }
    } catch (ex) {
      ClientLogging.logError(ex, "Set form row ads:" + currentPageName);
    }
  };

  fetchVehicleModelList = async (
    manufactureValue,
    engineCCValue,
    yearOfManufactureValue
  ) => {
    const vehicleModelListResponse = await vehicleLookupHelper.GetVehicleModelList(
      manufactureValue,
      engineCCValue,
      yearOfManufactureValue
    );

    if (
      !isNullOrUndefined(vehicleModelListResponse) &&
      !isNullOrUndefined(vehicleModelListResponse.data) &&
      !vehicleModelListResponse.data.isError
    ) {
      this.vehicleModelListFetchSuccess(vehicleModelListResponse);
    }
  };

  vehicleModelListFetchSuccess = (modelListResponse) => {
    const licenceTypeName = "driving-licence";
    const dateOfBirthName = "date-of-birth";

    //display only the models under 50cc
    if (modelListResponse.data.isError || modelListResponse.data.length === 0) {
      this.setFormData({
        [controlNames.bikeModel]: "",
      });
      this.setVehicleModelsList([]);
    } else {
      let filteredModelList = getFilteredModelList(modelListResponse.data);

      if (filteredModelList !== 0) {
        filteredModelList = [
          {
            key: "",
            description: "Choose your bike model",
            engineSize: "",
          },
        ].concat(
          filteredModelList.map((item) => {
            return {
              key: item.abiCode,
              description: item.displayedModel,
              engineSize: item.engineSize,
            };
          })
        );

        this.setVehicleModelsList([...filteredModelList]);

        this.setFormData({
          "bike-model": "",
        });
      }
    }
  };

  getField = (key) => {
    return this[key];
  };

  setField = (key, value) => {
    this[key] = value;
  };

  getShouldRevalidate = (controlName) => {
    return this.shouldRevalidate.has(controlName);
  };

  addShouldRevalidate = (controlName) => {
    this.shouldRevalidate.add(controlName);
  };

  removeShouldRevalidate = (controlName) => {
    this.shouldRevalidate.delete(controlName);
  };

  getShouldClearValidation = (controlName) => {
    return this.shouldClearValidation.has(controlName);
  };

  addShouldClearValidation = (controlName) => {
    this.shouldClearValidation.add(controlName);
  };

  removeShouldClearValidation = (controlName) => {
    this.shouldClearValidation.delete(controlName);
  };

  setPolicyCarToBeRevealed = (bool) => {
    this.policyCarToBeRevealed = bool;
  };

  getPolicyCarToBeRevealed = () => {
    return this.policyCarToBeRevealed;
  };
}

export default new AppStore();
