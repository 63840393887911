export const getDeviceType = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const windowWidth = window.innerWidth;
  const isMobile = /iphone|ipod|android.*mobile|windows phone/.test(userAgent);
  const isTablet =
    /ipad|android(?!.*mobile)|tablet/.test(userAgent) ||
    (windowWidth >= 768 && windowWidth <= 1024);
  if (isMobile) return "M";
  if (isTablet) return "T";
  return "D";
};
